<app-navbar-one></app-navbar-one>

<div class="saas-home-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-content">
                            <span>Digital Armor</span>
                            <h1>Leading Growth in Cyber Security and AI</h1>
                            <p>
                                In an era where digital landscapes are
                                perpetually at risk, Digital Armor stands as a
                                beacon of security. Our approach uses advanced
                                technology with deep expertise, offering robust
                                protection against cyber threats. With us,
                                safeguard your digital assets, ensuring their
                                integrity, confidentiality, and availability
                                against any risks.
                            </p>
                            <div class="saas-btn">
                                <a routerLink="/services" class="saas-btn-one">Our Services
                                    <i class="fa fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="saas-image">
                            <img src="assets/img/saas-banner/mobile.webp" alt="image" />
                            <img class="banner-tab" src="assets/img/saas-banner/mobile-two.webp" alt="image" height="800px" width="800px" />
                        </div>
                    </div>

                    <div class="circle-img">
                        <img src="assets/img/saas-banner/circle.webp" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape-img1">
        <img src="assets/img/shape/shape1.svg" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.svg" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
    <div class="shape-img4">
        <img src="assets/img/shape/shape4.png" alt="image" />
    </div>
    <div class="shape-img5">
        <img src="assets/img/shape/shape6.png" alt="image" />
    </div>
</div>

<section class="features-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="features-image">
                    <img src="assets/img/features/features-img1.webp" alt="image" />

                    <div class="features-shape">
                        <img src="assets/img/features/shape.webp" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.6s" alt="image" />
                    </div>

                    <div class="features-arrow">
                        <img src="assets/img/features/features-img2.webp"
                            class="wow animate__animated animate__fadeInRight" data-wow-delay="0.6s" alt="image" />
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="features-area-content">
                    <span>Artificial Intelligence</span>
                    <h2>"The New Frontier"</h2>
                    <p>
                        AI has steadily grown into the next frontier, work with us to bring new tailored solutions to
                        your organization
                    </p>

                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-f4ffb3">
                                <div class="icon">
                                    <i class="flaticon-claim"></i>
                                </div>
                                <h3>Research</h3>
                                <p>
                                    We are ever researching on the latest technologies.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="features-text bg-ffe7dc">
                                <div class="icon">
                                    <i class="flaticon-lock"></i>
                                </div>
                                <h3>Cloud Supported</h3>
                                <p>
                                    Take advantage of cloud technologies in your next implementation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="fun-facts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="sign-icon">$</span>
                        <span class="odometer" data-count="4.45"></span>
                    </h3>
                    <p>Million, Cost of data breaches</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="5212"></span><span class="sign-icon"></span>
                    </h3>
                    <p>Confirmed Data Breaches</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="48">48</span><span class="sign-icon">%</span>
                    </h3>
                    <p>Increase in the use of AI in Cyber Attacks</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="38"></span><span class="sign-icon">%</span>
                    </h3>
                    <p>Increase in Global Cyber Attacks</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="benefit-section pb-70">
    <div class="container">
        <div class="section-title">
            <span>Cyber Security</span>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-diagram"></i>
                    <h3>Cost of Security</h3>
                    <p>
                        Compromised systems can cost more than pro-active defences.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-money"></i>
                    <h3>Cost of Security</h3>
                    <p>
                        Compromised systems can cost more than pro-active defences.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-pen"></i>
                    <h3>Security Architecture</h3>
                    <p>
                        Consult on the latest and most effective security Architectures.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="benefit-area-content">
                    <i class="flaticon-refresh"></i>
                    <h3>Cyber Audits</h3>
                    <p>
                        Constantly audited and updated systems against cyber threats.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="talk-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="talk-form">
                    <form>
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" placeholder="What's Your Name" />
                        </div>

                        <div class="form-group">
                            <input type="email" name="email" class="form-control"
                                placeholder="Your email address here" />
                        </div>

                        <div class="form-group">
                            <input type="text" name="subject" class="form-control" placeholder="Subject" />
                        </div>

                        <div class="form-group">
                            <textarea name="message" class="form-control" cols="30" rows="6"
                                placeholder="Write your text here"></textarea>
                        </div>

                        <button type="submit" class="btn btn-primary" aria-label="Submit Now">
                            Submit Now
                        </button>
                    </form>
                </div>

                <div class="talk-image">
                    <div class="talk-shape">
                        <img src="assets/img/talk/talk-img1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.6s" alt="image" />
                    </div>

                    <div class="talk-arrow">
                        <img src="assets/img/talk/talk-img2.png" class="wow animate__animated animate__fadeInRight"
                            data-wow-delay="0.6s" alt="image" />
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="talk-content-area">
                    <span>Have any queries?</span>
                    <h3>Feel free to contact us</h3>


                    <div class="talk-info">
                        <h3>+254 716 823 103</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
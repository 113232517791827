<app-navbar-one></app-navbar-one>

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="productive-section ptb-100">
    <div class="container">
        <div class="productive-title">
            <h3>Cyber Security And AI Services</h3>
            <p>We offer a all-encomposed solution for Cyber security solutions as well as consultancy.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-headphones"></i>
                    </div>
                    <h3>Security Audits</h3>
                    <p>Our team of professionals attack and assess your organizations security posture</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-tag"></i>
                    </div>
                    <h3>Due diligence</h3>
                    <p>We offer due diligence services for persons and organizations</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-cyber-security"></i>
                    </div>
                    <h3>Global Presence</h3>
                    <p>Globally used tools for best practices</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-innovation"></i>
                    </div>
                    <h3>Innovative Ideas</h3>
                    <p>Consultancy to take your organization to the next level.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-content"></i>
                    </div>
                    <h3>Value for money</h3>
                    <p>Competitvely priced services.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="productive-area-content">
                    <div class="icon">
                        <i class="flaticon-unlink"></i>
                    </div>
                    <h3>AI</h3>
                    <p>Working on the next frontier AI.</p>
                </div>
            </div>
        </div>
    </div>
</section>

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SaasHomeComponent } from "./components/pages/saas-home/saas-home.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ServicesComponent } from "./components/pages/services/services.component";

const routes: Routes = [
    { path: "", component: SaasHomeComponent },
    { path: "services", component: ServicesComponent },
    { path: "error", component: ErrorComponent },
    { path: "contact", component: ContactComponent },
    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}

<div class="navbar-area">
    <div class="exto-responsive-nav">
        <div class="container">
            <div class="exto-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets/img/logo/logo.webp" alt="logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="exto-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo/logo.webp" alt="logo" />
                </a>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                href="javascript:void(0)"
                                a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Home</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/services"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                                >Services</a
                            >
                        </li>


                        <li class="nav-item">
                            <a
                                routerLink="/contact"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Contact Us</a
                            >
                        </li>
                    </ul>


                </div>
            </nav>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { NgOptimizedImage } from '@angular/common'
@Component({
  selector: 'app-saas-home',
  templateUrl: './saas-home.component.html',
  styleUrls: ['./saas-home.component.scss']
})
export class SaasHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
